import React from 'react';
import { useAnalytics } from 'reactfire';
import { Navigation } from '../components/navigation';
import { MiniMasthead } from '../display/MiniMasthead';
import OrderForm from './OrderForm';



export const Failure = () => {

  const analytics = useAnalytics();

  return (
    <div>
      <Navigation></Navigation>
      <MiniMasthead heading="Payment Unsuccessful" subheading="You haven't been charged"></MiniMasthead>

      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-2">
              <div className="p-5">
                <img className="img-fluid rounded-circle" src="/beer_glass.jpeg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="p-5">
                <h2 id="buy" className="display-4">Try again?</h2>
                <p>We're not sure what happened, but you haven't managed to pay for your tickets.</p>
                <p>Please try again using the order form below.</p>
                <p>If you keep having issues email <a href="mailto:cheers@doms.beer">cheers@doms.beer</a> to get in touch. We'll answer when we're home from the pub.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
      <div className="container">
          <div className="row align-items-center mb-5">

        <OrderForm></OrderForm>
        </div>
        </div>
      </section>


    </div>
  );
};
