import * as React from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  Badge,
  Button,
  Form,
  InputGroup,
  Modal,
  Table,
} from "react-bootstrap";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
} from "reactfire";

import { Beer } from "../types";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../display/LoadingSpinner";
import { VotingConfig } from "./Vote";

export const Beers = () => {
  const firestore = useFirestore();

  const votingConfigDocRef = firestore.doc('config/voting');

  const {status: configStatus, data: config} = useFirestoreDocData<VotingConfig>(votingConfigDocRef);

  const { register, handleSubmit } = useForm<Beer>();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const onSubmit = handleSubmit(async (beer: Beer, e) => {
    console.log(beer);
    const createSuccess = await createBeer(beer);
    if (createSuccess) {
      e?.target.reset();
      setShow(false);
    }
  });

  const createBeer = async (beer: Beer) : Promise<boolean> => {

    // const productID = product.id;

    // delete product.id;

    try {
      const result = await firestore
      .collection(`/beers`).add(beer);

      toast(`Beer created`);
      return true;
      
    } catch (error) {
      console.error(error);
      toast(`Error creating Beer`);
    }
    return false;
  };

  const toggleVoting = async () => {
    try {
      const result = await firestore
      .doc(`/config/voting`).set({votingOpen: !config.votingOpen});

      toast(`Voting status changed`);
      
    } catch (error) {
      console.error(error);
      toast(`Error changing vote status`);
    }
  }

  if (configStatus === 'loading' || !config) {
    return (<LoadingSpinner></LoadingSpinner>)
  }

  return (
    <div className="m-5">
      <h2>Voting</h2>
      <div className="m-3">

      {config.votingOpen? <>
        <section>
        <h2><Badge variant="primary">Voting is open</Badge></h2>
      </section>      
      </> : <>
      <h2><Badge variant="warning">Voting is closed</Badge></h2>
      </>}
      </div>

      <Button className="mt-3 mb-3" onClick={() => toggleVoting()}>
        Toggle voting
      </Button>
      <h2>Beer</h2>
      <Button className="mt-3 mb-3" onClick={() => setShow(true)}>
        Add new beer
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Beer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                ref={register}
                as="input"
                placeholder="e.g. Misty Miyagi"
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Brewer</Form.Label>
              <Form.Control
                name="brewer"
                as="input"
                ref={register}
                placeholder="e.g. Deep Creek"
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Control
                name="type"
                as="input"
                ref={register}
                placeholder="e.g. 'IPA' 'Hazy IPA', 'Porter'"
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>ABV</Form.Label>
              <InputGroup>
              <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
              </InputGroup.Append>
                <Form.Control
                  name="ABV"
                  as="input"
                  ref={register}
                  type="number"
                  step="0.1"
                  placeholder="5.0"
                ></Form.Control>
              </InputGroup>
            </Form.Group>
            <Button type="submit">Create</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {/* <Button variant="primary" onClick={createAccount}>
            Save
          </Button> */}
        </Modal.Footer>
      </Modal>
      <BeerList />
    </div>
  );
};

const BeerList = () => {
  const firestore = useFirestore();

  const productCollection = firestore.collection("beers").orderBy("brewer");

  const { status, data: beers } = useFirestoreCollectionData<Beer>(
    productCollection,
    { idField: "id" }
  );

  if (status === "loading") {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Brewer</th>
            <th>Type</th>
            <th>ABV</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {beers.map((beer) => (
            <BeerRow key={beer.id as string} beer={beer}></BeerRow>
          ))}
        </tbody>
      </Table>
    </>
  );
};

type BeerRowData = {
  beer: Beer
}

const BeerRow = ({beer} : BeerRowData) => {
  
  const firestore = useFirestore();

  const { register, handleSubmit } = useForm<Beer>();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const onSubmit = handleSubmit(async (beer: Beer, e) => {
    console.log(beer);

    const beerID = beer.id;

    const dataToUpdate : any = {...beer};
    delete dataToUpdate.id;

    try {
      const result = await firestore
      .collection(`/beers`).doc(beerID).update(dataToUpdate);
      toast(`Beer updated`);
      setShow(false);
      return true;
      
    } catch (error) {
      console.error(error);
      toast(`Error creating Beer`);
    }
    return false;

  });

  return (
    <tr>
      <td>{beer.name}</td>
      <td>{beer.brewer}</td>
      <td>{beer.type}</td>
      <td>{beer.ABV}%</td>
      <td> 
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Beer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
              <Form.Control
                name="id"
                ref={register}
                defaultValue={beer.id}
                as="input"
                type="hidden"
              ></Form.Control>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                ref={register}
                defaultValue={beer.name}
                as="input"
                placeholder="e.g. Misty Miyagi"
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Brewer</Form.Label>
              <Form.Control
                name="brewer"
                as="input"
                ref={register}
                defaultValue={beer.brewer}
                placeholder="e.g. Deep Creek"
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Control
                name="type"
                as="input"
                ref={register}
                defaultValue={beer.type}
                placeholder="e.g. 'IPA' 'Hazy IPA', 'Porter'"
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>ABV</Form.Label>
              <InputGroup>
              <InputGroup.Append>
              <InputGroup.Text>%</InputGroup.Text>
              </InputGroup.Append>
                <Form.Control
                  name="ABV"
                  as="input"
                  ref={register}
                  defaultValue={beer.ABV}
                  type="number"
                  step="0.1"
                  placeholder="5.0"
                ></Form.Control>
              </InputGroup>
            </Form.Group>
            <Button type="submit">Save</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
        
        <Button onClick={() => setShow(true)} type="submit">Update</Button>
      
      
      
      </td>
    </tr>
  );


}