import { auth, useAuth, useUser } from "reactfire";

export const Navigation = () => {

  const auth = useAuth();
  const { data: user } = useUser();

  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
    <div className="container">
      <a className="navbar-brand" href="/">Meadowbank Beer Night</a>
      {/* {user ? 
            <li className="nav-item">
            <button className="navbar-toggler" onClick={event => (auth.signOut().then(() => console.log("signed out")))} >Sign Out</button>
            </li>
            : <></>
      } */}

      {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button> */}
      <div id="navbarResponsive">
        <ul className="navbar-nav ml-auto">
          {/* <li className="nav-item">
          <a className="nav-link" href="/Dashboard">{user ? `Signed in as ${user.email}` : "Not signed in"}</a>
          </li> */}
          {user ? 
            <li className="nav-item">
            <a className="nav-link" onClick={event => (auth.signOut().then(() => console.log("signed out")))} >Sign Out</a>
            </li>
            : <></>
          }
        </ul>
      </div>
    </div>
  </nav>
    </>
  );
}