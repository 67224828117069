import React from 'react';
import { useAnalytics } from 'reactfire';
import { Navigation } from '../components/navigation';
import { MiniMasthead } from '../display/MiniMasthead';



export const Success = () => {

  const analytics = useAnalytics();

  return (
    <div>
      <Navigation></Navigation>
      <MiniMasthead heading="Payment Successful" subheading="See you and your crew on June 15th"></MiniMasthead>

      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-2">
              <div className="p-5">
                <img className="img-fluid rounded-circle" src="/beer_glass.jpeg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="p-5">
                <h2 id="buy" className="display-4">You're all good to go...</h2>
                <p>We'll email out some event info the week before. In the meantime, start preparing your taste buds for a night of craft beers and community building.</p>
                <p>Any questions, email <a href="mailto:cheers@doms.beer">cheers@doms.beer</a> to get in touch. We'll answer when we're home from the pub.</p>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};
