
type MastheadProps = {
  heading: string, subheading: string
}

export const MiniMasthead = ({ heading, subheading } : MastheadProps) => {

  return (
    <>
      <header className="masthead-mini text-center text-white">
      <div className="masthead-content">
        <div className="container">
          <h1 className="masthead-heading mb-0">{heading}</h1>
          <h2 className="masthead-subheading mb-0">{subheading}</h2>
          <br/>
        </div>
      </div>
      <div className="bg-circle-1 bg-circle" />
      <div className="bg-circle-2 bg-circle" />
      <div className="bg-circle-3 bg-circle" />
      <div className="bg-circle-4 bg-circle" />
    </header>
    </>

  );



}

