import React from "react";
import {
  Badge,
  Button,
  Card,
  CardColumns,
  CardDeck,
  Carousel,
  Col,
  Navbar,
  Row,
} from "react-bootstrap";
import { useAnalytics } from "reactfire";
import { Navigation } from "../components/navigation";
import { MiniMasthead } from "../display/MiniMasthead";
import OrderForm from "./OrderForm";

export const Home = () => {
  const analytics = useAnalytics();

  const orderingOpen = false;

  const logos : string[] = [
    "2024.png",
    // "brothers.jpg",
    // "deep_creek_logo.png",
    // "isthmus.jpeg",
    // "Sawmill.png"
  ];

  return (
    <div>
      <Navigation></Navigation>
      <MiniMasthead
        heading="Meadowbank School Beer Night"
        subheading="Saturday June 15th 2024, 7pm start"
      ></MiniMasthead>

      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-2">
              <div className="p-5">
                <Carousel>
                  {logos.map((logo) => (
                    <Carousel.Item key={logo}>
                      <img
                        className="d-block w-100 h-100"
                        src={`/logos/${logo}`}
                        alt="Brewer logo"
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="p-5">
                <h2 id="buy" className="display-4">
                  Awesome breweries, your friends, and a good cause...
                </h2>
                <p>
                  {" "}
                  Come along and support a great event to raise money for
                  Meadowbank School and learn more about craft beer.
                </p>
                <p>
                  {" "}
                  We've locked in some top breweries, and have more to come.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {orderingOpen ? (
        <>
          <section>
            <div className="container">
              <h1>Ticket options</h1>
              <div className="row align-items-center">
                <CardDeck className="mb-5">
                  <Card>
                    <Card.Body>
                      <Card.Title>Ticket and Tasters Only</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        <div className="d-flex justify-content-between">
                          <div>Just the basics</div>
                        </div>
                      </Card.Subtitle>
                      <Card.Text>
                        This gets you in the door, and a taster from each
                        brewery.
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <h1>
                        <a href="#order">
                          <Badge variant="secondary">$45</Badge>
                        </a>
                      </h1>
                    </Card.Footer>
                  </Card>

                  <Card>
                    {/* <Card.Header as="h5">Most Popular</Card.Header> */}
                    <Card.Body>
                      <Card.Title>Ticket, Taster and 5 Full Beers</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        <div className="d-flex justify-content-between">
                          <div>The real deal</div>
                        </div>
                      </Card.Subtitle>
                      <Card.Text>
                        The best value - gets you in, tasters from each brewery,
                        plus 5 full pours from whichever brewer you choose.
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <h1>
                        <a href="#order">
                          <Badge variant="secondary">$85</Badge>
                        </a>
                      </h1>
                    </Card.Footer>
                  </Card>
                </CardDeck>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div id="order" className=" bg-light row align-items-center mb-5">
                <OrderForm></OrderForm>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section>
            <div className="container">
              <div className="row align-items-center p-5">
                <h1>Sorry - Online Ticket presales is now closed</h1>
                <p>We will have limited door sales available on the night (EFTPOS only).</p>
                <p>We look forward to seeing you there!</p>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};
