import {
  Table,
} from "react-bootstrap";
import {
  useFirestore,
  useFirestoreCollectionData,
} from "reactfire";

import firebase from "firebase/app";

import { LoadingSpinner } from "../display/LoadingSpinner";
import Moment from "react-moment";

export interface UserDetail {
  id: string;
  full_name: string
  display_name: string;
  primary_email: string;
  created_at: firebase.firestore.Timestamp

}

export const Users = () => {
  const firestore = useFirestore();




  return (
    <div className="m-5">
      <h2>Users</h2>
      <UserList />
    </div>
  );
};

const UserList = () => {
  const firestore = useFirestore();

  const userCollection = firestore.collection("user").orderBy("created_at", "desc");

  const { status, data: users } = useFirestoreCollectionData<UserDetail>(
    userCollection,
    { idField: "id" }
  );

  if (status === "loading") {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
                <tr key={user.id}>
                <td>{user.display_name}</td>
                <td>{user.primary_email}</td>
                <td><Moment fromNow>{user.created_at?.seconds*1000}</Moment></td>
                  </tr> 
                    ))}
        </tbody>
      </Table>
    </>
  );
};
