import React, { useState } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";

import { Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";



// TEST_TRUST
// const stripePromise = loadStripe(
//   "pk_test_51IofHaLLCTJoOwcIP025C3xvUy68AfT0aQUqh6YpfJmCk5APKvW7oRqhswhoeXA3VxzJMz03z3en9hP16ZkqzOO600YHlxZnrM"
// );

// PROD_SITE
// const stripePromise = loadStripe(
//   "pk_live_51IoyLMErMc2fuwug7RoVmwchIFQCAN5uq9JzgBoeR5hcKSE7RLa4BT936DdGUsV25f77iOQR9IyuNCPUyjKxUT1o00vY16WNHt"
// );

// TEST_SITE
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY ? process.env.REACT_APP_STRIPE_KEY : "nokey"
);



type OrderFormData = {
  name: string;
  email: string;
  basicQuantity: number;
  fullQuantity: number;
};

export default function OrderForm() {
  
  const { register, setValue, getValues, errors, handleSubmit } = useForm<OrderFormData>({defaultValues: {basicQuantity: 0, fullQuantity: 0}});


  const submitPayment = async (email : string, basicQuantity : number, fullQuantity : number, name: string) => {

    const stripe = await stripePromise;

    const lineItems = [];

    if (basicQuantity > 0) {
      lineItems.push( {
        //TEST_TRUST price: "price_1IofflLLCTJoOwcIAiw5DlKN",
        price: process.env.REACT_APP_BASIC_PRICE_ID,
        quantity: +basicQuantity,
      });
    }

    if (fullQuantity > 0) {
      lineItems.push( {
        //TEST_TRUST price: "price_1IofgeLLCTJoOwcIlLS0EAvh",
        price: process.env.REACT_APP_FULL_PRICE_ID,
        quantity: +fullQuantity,
      });
    }

    if (lineItems.length < 1) {
      toast.error("Sorry, your ticket quantities aren't valid. Please check again");
      return;
    }

    if (stripe) {
      try {
        toast.info(`Redirecting to stripe for payment`);
        const { error } = await stripe.redirectToCheckout({
          mode: "payment",
          lineItems,
          successUrl: `${window.location.origin}/success`,
          cancelUrl: `${window.location.origin}/failure`,
          customerEmail: email,
          clientReferenceId: name
        });
        
        if (error) {
          toast.error(`Something went wrong with the shopping cart. ${error.message}`);
        }
      } catch (error) {
        toast.error(`Something went wrong with the shopping cart. ${error.message}`);
      }
    }
  };

  const onSubmit = handleSubmit(async ({ email, basicQuantity, fullQuantity, name }) => { 

    console.log(email, name, basicQuantity, fullQuantity);

    if (name && email && (basicQuantity > 0 || fullQuantity > 0) && !(basicQuantity < 0 || fullQuantity < 0)) {
      //valid
      submitPayment(email, basicQuantity, fullQuantity, name);

    } else {
      console.log("invalid data");
    }
  });

  const basicCount = getValues().basicQuantity;

  return (
    <>

      <section className="m-5">
        <Row>
          <Col md={{ span: 8}}>
            <h3>Order your tickets now</h3>
            <p>Get in before they're all gone!</p>
          </Col>
        </Row>
        <Row >
          <Col md="12">
            <Form onSubmit={onSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Ticketholders Name</Form.Label>
              <Form.Control ref={register} name="name" placeholder="Enter name" />
              <Form.Text className="text-muted">
                Name of the person who'll pick up tickets at the event
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control ref={register} name="email" type="email" placeholder="Enter email" />
              <Form.Text className="text-muted">
                We'll use this email to communicate event information and send payment receipts to.
              </Form.Text>
            </Form.Group>
            <h5>Choose your ticket quantities</h5>
            <Form.Group as={Row} controlId="formBasicQuantity">
              <Form.Label column sm={8}>Entry and Taster tickets @ $45 each</Form.Label>
              <Col md={4}>
              <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text onClick={(event: any) => { setValue('basicQuantity', +(getValues().basicQuantity - 1))}}>-</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control ref={register} name="basicQuantity" min={0} max={20} type="number"/>
              <InputGroup.Append>
                <InputGroup.Text onClick={(event: any) => { setValue('basicQuantity',  1 + +(getValues().basicQuantity))}}>+</InputGroup.Text>
              </InputGroup.Append>
              </InputGroup>

              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formFullQuantity">
            <Form.Label column sm={8}>Entry, Taster and 5 Full Beers @ $85 each</Form.Label>
            <Col md={4}>
              <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text onClick={(event: any) => { setValue('fullQuantity', +(getValues().fullQuantity - 1))}}>-</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control ref={register} name="fullQuantity" min={0} max={20} type="number"/>
              <InputGroup.Append>
                <InputGroup.Text onClick={(event: any) => { setValue('fullQuantity',  1 + +(getValues().fullQuantity))}}>+</InputGroup.Text>
              </InputGroup.Append>
              </InputGroup>
              </Col>
            </Form.Group>

              {/* <Button
                onClick={(event) => onPaymentSubmit()}
                size="lg"
                className="rounded-pill"
              > */}
              <Button type="submit" size="lg" className="rounded-pill">
                Proceed to payment
              </Button>
            </Form>
          </Col>

          <Col md="3" />
        </Row>
      </section>

    </>
  );
}
