import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/beernight.scss';
import { loadStripe } from '@stripe/stripe-js';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD0704HH80Qz5YvPS6So8gfyQHeDuTcqlU",
  authDomain: "beer-night.firebaseapp.com",
  projectId: "beer-night",
  storageBucket: "beer-night.appspot.com",
  messagingSenderId: "292409099349",
  appId: "1:292409099349:web:f7bed1223553f33d2780dc",
  measurementId: "G-ESVKZR7J0M"
};

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Could not find root element');
}

ReactDOM.render(
  <App firebaseConfig={firebaseConfig} />
, rootElement);
  