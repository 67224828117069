import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Moment from 'react-moment';
import { useAnalytics } from 'reactfire';
import { Navigation } from '../components/navigation';
import { LoadingSpinner } from '../display/LoadingSpinner';
import { MiniMasthead } from '../display/MiniMasthead';


export interface PaymentInfo {
  created: number;
  name:  string;
  email: string;
  total: number;
  entryOnlyTicketQuantity: number;
  entryAnd5BeersTicketQuanity: number;
};

export interface BackendError {
  statusCode:  number;
  message: string;
};

type FormData = {
  password: string;
};


export const Orders = () => {

  const [password, setPassword] = useState("");

  const { register, setValue, getValues, errors, handleSubmit } = useForm<FormData>();

  const analytics = useAnalytics();

  const onSubmit = handleSubmit(async ({ password }) => { 
    setPassword(password);
  });

  return (
    <div>

      <section>
        <div className="container">
          <h1>Orders</h1>
          
          <Row >
          <Col md="12">
            <Form onSubmit={onSubmit}>
            <Form.Group controlId="formName">
              <Form.Control ref={register} name="password" placeholder="Enter password" />
            </Form.Group>
              <Button type="submit" size="lg" className="rounded-pill">
                Search
              </Button>
            </Form>
          </Col>
          </Row>
          {password ? <OrderResults password={password}/> : <p>enter password</p> }
          
        </div>
      </section>


    </div>
  );
};

type OrderResults = {
  password : string
}

export const OrderResults = ({password} : OrderResults) => {

  const analytics = useAnalytics();

  const [error, setError] = useState<BackendError|null>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [orders, setOrders] = useState<PaymentInfo[]>([]);

  useEffect(() => {

    const getData = async () => {

      const requestOptions = {
        method: "POST", 
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({token : password})
      }

      const response = await fetch("/api/orders", requestOptions);

      if (response.status >= 200 && response.status < 400) {
        const result = (await response.json()) as PaymentInfo[];
        setIsLoaded(true);
        setOrders(result);
        setError(null);
      } else {
        setIsLoaded(true);
        const error = (await response.json()) as BackendError
        setError(error);
      }
    }
    getData(); 
  }, [password])

  if (error) {
    return <div>Error: {error.message} {password}</div>;
  } else if (!isLoaded || orders.length == 0) {
    return <LoadingSpinner/>;
  } else {

    let totalResult = {created: Date.now(), name: "Total", email : "-", total: 0, entryAnd5BeersTicketQuanity : 0, entryOnlyTicketQuantity: 0 };
    
    orders.forEach(currentValue => {
      totalResult.total += currentValue.total;
      totalResult.entryOnlyTicketQuantity += currentValue.entryOnlyTicketQuantity;
      totalResult.entryAnd5BeersTicketQuanity += currentValue.entryAnd5BeersTicketQuanity;
    } ); 

    const totalOrders = [...orders, totalResult];

    return (
      <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Created</th>
            <th>Name</th>
            <th>Email</th>
            <th>Total</th>
            <th>Entry Tickets ($45)</th>
            <th>Entry Tickets + 5 pours ($45)</th>
          </tr>
        </thead>
        <tbody>
          {totalOrders.map(order => (
            <tr key={order.email + order.created}>
              <td><Moment format="DD MMM YY HH:mm a">
                {order.created}
                </Moment>
              </td>
              <td>
                {order.name}
              </td>
              <td>{order.email}</td>
              <td>${order.total}</td>
              <td>
                {order.entryOnlyTicketQuantity}
              </td>
              <td>
                {order.entryAnd5BeersTicketQuanity}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>

    );
  }

};
