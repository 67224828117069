import React from 'react';
import './App.css';

import { AuthCheck, FirebaseAppProvider } from 'reactfire';

import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

// Import auth directly because most components need it
// Other Firebase libraries can be lazy-loaded as-needed
import 'firebase/auth';

import 'firebase/firestore';
import 'firebase/analytics';
import { Home } from './pages/Home';
import { Success } from './pages/Success';
import { Failure } from './pages/Failure';
import { Orders } from './pages/Orders';
import firebase from 'firebase';
import { Vote } from './pages/Vote';
import { Auth } from './auth/Auth';
import { Beers } from './pages/Beers';
import { Results } from './pages/Results';
import { Users } from './pages/Users';

export const App = ({ firebaseConfig }: { firebaseConfig: { [key: string]: unknown } }) => {

  // firebase.auth().signInAnonymously().then(() => {
  //   // Signed in..
  //   console.log("Signed in anonymously");
  // })
  // .catch((error) => {
  //   const errorCode = error.code;
  //   const errorMessage = error.message;
  //   console.error(errorCode, errorMessage);
  // });

  return (
    <Router>
      <ToastContainer />
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/success" component={Success} />
              <Route exact path="/failure" component={Failure} />
              <Route exact path="/orders" component={Orders} />
              <AuthCheck fallback={<Auth />}>
                <Route exact path="/vote" component={Vote} />
                <Route exact path="/beers" component={Beers} />
                <Route exact path="/results" component={Results} />
                <Route exact path="/userlist" component={Users} />
              </AuthCheck>

              <Route component={Home} />
            </Switch>
      </FirebaseAppProvider>
    </Router>
  );
}


