import * as React from "react";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  Table,
} from "react-bootstrap";
import {
  useFirestore,
  useFirestoreCollectionData,
} from "reactfire";

import CanvasJSReact from '../canvasjs.react';

import { Beer, BeerRating } from "../types";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../display/LoadingSpinner";
import { Navigation } from "../components/navigation";
import { MiniMasthead } from "../display/MiniMasthead";

type RatingMap = {
  [key: string] : BeerRating[]
}

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const Results = () => {
  const firestore = useFirestore();

  const beerCollection = firestore.collection("beers");

  const { status, data: beers } = useFirestoreCollectionData<Beer>(
    beerCollection,
    { idField: "id" }
  );

  const [ratings, setRatings] = useState(new Map<string, BeerRating[]>());
  
  const getRatingCount = (beerId: string) => {
    return ratings.get(beerId)?.length ?? 0;
  }

  const getAverageRating = (beerId: string) => {
    const currentRatings = ratings.get(beerId);
    if (!currentRatings || currentRatings.length == 0) {
      return 0;
    }
    let total = 0;
    currentRatings.forEach(rating => total += rating.rating);
    return Math.round((total * 100) / currentRatings.length) / 100;

  }

  const getRatings = () => {
    const query = firestore
    .collectionGroup("ratings")
    .get()
    .then((querySnapshot) => {
      const ratingMap = new Map<string, BeerRating[]>();           
        querySnapshot.forEach((doc) => {

           const values = ratingMap.get(doc.id);
           if (values) {
             ratingMap.set(doc.id, [...values, doc.data() as BeerRating])
           } else {
            ratingMap.set(doc.id, [doc.data() as BeerRating])
           }
        });
        console.log(ratingMap);
        setRatings(ratingMap);
    }).catch((error) => {
        console.log("Error getting documents: ", error);
        toast("You are not allowed to view this page")
    });
  }

  useEffect(() => {
    const interval_id = setInterval(getRatings, 10000);
    return () => {
      // Stop the interval when the component unmounts. 
      // Otherwise it will keeep going and you will get an error.
      clearInterval(interval_id)
    }
  }, []);

  if (status === "loading" || ratings.size < 1) {
    return <LoadingSpinner />;
  }

  const beerRatings: { label: string; y: number; }[] = [];

  //const beerComments : { beer: string; rating: number; notes: string }[] = []

  

  beers.forEach(beer => {
    beerRatings.push({
      label: beer.name,
      y: getAverageRating(beer.id)
    }
    )
  })

  beerRatings.sort((a, b) => {
    return a.y - b.y;
  })

  const options = {
    title: {
      text: "Beer Ratings"
    },
    data: [{				
              type: "bar",
              dataPoints: beerRatings
    }],
    axisY: {
      minimum : 0,
      maximum : 5,
      interval : 0.5
    }
 }

  return (
    <>
      <Navigation></Navigation>
      <MiniMasthead heading="Results" subheading="Winner, winner"></MiniMasthead>

      <div className="m-5">
        <h1>Beer Rankings</h1>
        <CanvasJSChart options = {options} />

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Brewer</th>
              <th>Votes</th>
              <th>Average</th>
            </tr>
          </thead>
          <tbody>
        {beers.map((beer) => (
          <tr key={beer.id as string}>
            <td>
            {beer.name} 
            </td>
            <td>{beer.brewer}</td>
            <td>{getRatingCount(beer.id)}</td> 
            <td>{getAverageRating(beer.id)}</td>
          </tr>
        ))}
        </tbody>
        </Table>

      <Table striped bordered hover>
          <thead>
            <tr>
              <th>Beer</th>
              <th>Rating</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
        {beers.map((beer) => (
          <>
          {ratings.get(beer.id)?.map((rating : BeerRating, index: number) => (
            <>
            {
              rating.tastingNote ? 
              <tr key={(beer.id + index) as string}>
              <td>
              {beer.name} 
              </td>
              <td>{rating.rating}</td> 
              <td>{rating.tastingNote}</td>
            </tr> : <></>
              }
              </>
          ))
          }
          </>
        ))}
        </tbody>
        </Table>

        </div>
    </>
  );
};
