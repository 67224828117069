import firebase from "firebase";
import React, { useState } from 'react';
import { Badge, Button, Card, CardColumns, Form, Modal } from 'react-bootstrap';
import { useAnalytics, useFirestore, useFirestoreCollectionData, useFirestoreDocData, useUser } from 'reactfire';
import { Navigation } from '../components/navigation';
import { LoadingSpinner } from '../display/LoadingSpinner';
import { MiniMasthead } from '../display/MiniMasthead';
import Rating from 'react-rating';
import { FaBeer } from 'react-icons/fa';
import { toast } from "react-toastify";
import { Beer, BeerRating } from "../types";


export interface VotingConfig {
  votingOpen: boolean
}

export const Vote = () => {

  const firestore = useFirestore();

  const { status: status, data: user } = useUser();

  const votingConfigDocRef = firestore.doc('config/voting');

  const {status: configStatus, data: config} = useFirestoreDocData<VotingConfig>(votingConfigDocRef);

  const analytics = useAnalytics();

  if (status === "loading" || !user || !config) {
    return (
    <div>
      <Navigation></Navigation>
      <MiniMasthead heading="Vote" subheading="Vote for your favourite beer"></MiniMasthead>

      <LoadingSpinner />;


    </div>);
  }

  return (
    <div>
      <Navigation></Navigation>
      <MiniMasthead heading="What do you fancy?" subheading="Rate each beer you taste"></MiniMasthead>

      <div className="m-3">

      {config.votingOpen? <>
        <section>
        <Badge variant="primary">Voting is open</Badge>
      </section>      
      </> : <>
      <h2><Badge variant="warning">Voting is closed</Badge></h2>
      </>}
      </div>
      <section>
        <BeerList user={user} allowVoting={config.votingOpen}></BeerList>
      </section>


    </div>
  );
};

interface BeerListData {
  user: firebase.User;
  allowVoting: boolean;
}

export const BeerList = ({user, allowVoting} : BeerListData) => {

  const firestore = useFirestore();

  const beerCollection = firestore.collection("beers").orderBy('brewer');

  const beerRatingCollection = firestore.collection(`user/${user.uid}/ratings`);


  const [show, setShow] = useState(false);
  const [tastingNote, setTastingNote] = useState("");
  const [beerID, setBeerID] = useState("");

  const handleClose = () => {
    setBeerID("");
    setTastingNote("");
    setShow(false)
  };

  const { status, data: beers, error: beerError } = useFirestoreCollectionData<Beer>(
    beerCollection,
    { idField: "id" }
  );

  const { status: ratingsStatus, data: beerRatings, error: ratingError } = useFirestoreCollectionData<BeerRating>(
    beerRatingCollection,
    { idField: "id" }
  );

  const deleteVote = async (id: string): Promise<void> => {
    try {
      await  firestore.doc(`user/${user.uid}/ratings/${id}`).delete();
    } catch (error) {
      toast("Error deleting");
    }
  }

  const onVoteChange = async (rating: number, beerId: string) => {
    console.log("changed", rating, beerId);

    try {
      await beerRatingCollection.doc(beerId).set({rating}, {merge: true});
    } catch (error) {
      toast("Error saving rating");
    }

  }

  const getRatingForBeer = (beer: Beer) => {
    const array = beerRatings ?? [];

    const beerRating = array.find(value => {
      return value.id == beer.id;
    })
    return beerRating;
  }

  const showTastingNote = (beer : Beer) => {
    const rating = getRatingForBeer(beer);
    console.log(rating);
    if (rating) {
      setTastingNote(rating.tastingNote ?? "");
      setBeerID(beer.id);
      setShow(true);
    }
  }

  const updateTasting = async () => {
    try {
      await beerRatingCollection.doc(beerID).update({tastingNote});
      handleClose();
    } catch (error) {
      toast("Error saving rating");
    }
  }


  if (status === "loading" || ratingsStatus == "loading") {
    return <LoadingSpinner />;
  }

  if (status === "error" || ratingsStatus == "error") {
    return <p>An error occured. Refresh the page to see if things come right</p>;
  }

  if (ratingError || beerError) {
    return (
      <>
      <h1>Error occurred. Refresh Page to see if it fixes itself</h1>
      <p>{ratingError} {beerError}</p>
      </>
    );
  }

  const getRatingValue = (beerId: string) : number => {

    const array = beerRatings ?? [];

    const beerRating = array.find(value => {
      return value.id == beerId;
    })
    return beerRating ? beerRating.rating : 0;
  }

  const warnVotingclosed = () => {
    toast("Voting is currently closed");
  }

  return (
    <div className="m-3">
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add a tasting note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Control
                name="tasting"
                as="textarea"
                rows={4}
                value={tastingNote}
                onChange={event => setTastingNote(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button disabled={!allowVoting} onClick={event => {updateTasting()}}>Save</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <CardColumns id="rating-cards" className="">
      {
        beers.map((beer) =>(
          <Card key={beer.id} border="primary">
          <Card.Body>
            <Card.Title>{beer.name} by {beer.brewer}</Card.Title>
            <Card.Subtitle>{beer.ABV}% - {beer.type}</Card.Subtitle>
            <Card.Text>
              <Rating
                emptySymbol={<FaBeer color="LightGray" size={50}/>}
                fullSymbol={<FaBeer color="Peru" size={50}/>}
                fractions={2}
                initialRating={getRatingValue(beer.id)}
                onChange={value => {onVoteChange(value, beer.id)}}
                readonly={!allowVoting}
              ></Rating>
              {allowVoting? <></> : <Badge variant="warning">Voting is currently closed</Badge>}
            </Card.Text>
            <Button disabled={getRatingValue(beer.id)==0 || !allowVoting} className="mr-2" variant="warning" onClick={value => deleteVote(beer.id)}>Clear rating</Button>
            <Button disabled={getRatingValue(beer.id)==0} variant="primary" onClick={value => showTastingNote(beer)}>Tasting notes</Button>
          </Card.Body>
        </Card>
        ))
      }
      </CardColumns>

    </div>
  );
};


