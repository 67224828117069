import firebase from "firebase";
import "firebase/auth";
import * as firebaseui from "firebaseui";

import * as React from "react";
import { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useAuth, useUser } from "reactfire";
import { Navigation } from "../components/navigation";
import { LoadingSpinner } from "../display/LoadingSpinner";
import { MiniMasthead } from "../display/MiniMasthead";

const signOut = (auth: firebase.auth.Auth) =>
  auth.signOut().then(() => console.log("signed out"));

export const AuthWrapper = ({
  children,
  fallback,
}: React.PropsWithChildren<{ fallback: JSX.Element }>): JSX.Element => {
  const { status, data: user, hasEmitted } = useUser();

  if (!children) {
    throw new Error("Children must be provided");
  }
  if (status === "loading" || hasEmitted === false) {
    return (
      <div>
        <LoadingSpinner />{" "}
      </div>
    );
  } else if (user) {
    return children as JSX.Element;
  }

  return fallback;
};

interface UserDetailsFormData {
  dirtyForm: boolean;
  displayName: string | null;
}

export const UserDetails = () => {
  const auth = useAuth();

  const { status, data: user } = useUser();

  const [formData, setFormData ] = useState<UserDetailsFormData>({dirtyForm: false, displayName: user.displayName});

  if (status === "loading") {
    return <LoadingSpinner />;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {

    console.log(formData);

    if (formData.displayName != null) {
      user.updateProfile({displayName: formData.displayName}).then(() => {
        console.log("updated");
        //TODO: work out how to tell other auth components to notice the change.
      })

    }

    event.preventDefault();
  };

  let displayName = user.displayName;

  const setDisplayName = (name : string) => {
    setFormData({dirtyForm: true, displayName: name});
  }

  return (
    <>
      <h1>User Details</h1>
      <Form
        id="UserDetails"
        className="mb-3 align-items-center"
        onSubmit={handleSubmit}
      >
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            id="email"
            placeholder="Email"
            as="input"
            value={user.email ? user.email : ""}
            readOnly={true}
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Display Name</Form.Label>
          <Form.Control
            id="displayName"
            placeholder="Display Name"
            as="input"
            //value={formData.displayName}
            defaultValue={user.displayName ? user.displayName : ""}
            onChange={(event) => setDisplayName(event.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          <Button variant="primary" disabled={!formData.dirtyForm} type="submit">
            Update
          </Button>
          {' '}
          <Button variant="danger" onClick={() => signOut(auth)}>
            Sign Out
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

const SignInForm = () => {
  const auth = useAuth;

  const uiConfig = {
    signInFlow: "redirect",
    signInOptions: [
      auth.EmailAuthProvider.PROVIDER_ID,
      {provider: auth.GoogleAuthProvider.PROVIDER_ID, client_id: "292409099349-6cs9oukf6h4dte12osjcjdiq5mg2bu42.apps.googleusercontent.com"}, 
      // {provider: auth.PhoneAuthProvider.PROVIDER_ID, defaultCountry: 'NZ'}
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
  };

  return (
    <>
    <Navigation></Navigation>
    <MiniMasthead
      heading="Get started"
      subheading="Let's get you setup to vote..."
    ></MiniMasthead>
    <div>
      <h2>Please sign in</h2>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth()} />
    </div>
    </>
  );
};

export const Auth = () => {
  const { status, data: user, hasEmitted } = useUser();

  if (status === "loading" ) {
    return (
      <div>
        <LoadingSpinner />{" "}
      </div>
    );
  }

  return user ? <UserDetails /> : <SignInForm />;
};
